import GATSBY_COMPILED_MDX from "/opt/build/repo/src/content/projects/yoga-of-words.mdx";
import {Flex, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Container, Heading, Text, Tag, Link, useColorMode} from "@chakra-ui/react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {ArrowBackIcon, ArrowForwardIcon} from "@chakra-ui/icons";
import {Link as GatsbyLink, graphql, useStaticQuery} from "gatsby";
import React, {useEffect} from "react";
import {generate} from "shortid";
import {HalfByHalfSection, LinkOne, MdxTOC, CardOne} from "components";
import MDXLayout from "components/base/layout/mdx-layout";
function CaseTemplate({children, path, pageContext, location}) {
  const {colorMode} = useColorMode();
  const {defaultImage} = useStaticQuery(query);
  const {next, previous, node: {frontmatter: {categories} = {}, tableOfContents, fields} = {}, title = ""} = pageContext || ({});
  const defaultImg = getImage(defaultImage);
  const NextArticle = () => {
    const {frontmatter: {featuredImage = "", subHeader = "", title: nextTitle = "", categories = []} = {}, fields: {path: nextPath = ""} = {}} = next || ({});
    console.log("next");
    console.log(next);
    const nextImage = featuredImage !== "" ? getImage(featuredImage) : defaultImg;
    return next === ({}) === false ? React.createElement(CardOne, {
      key: generate()
    }, nextPath && React.createElement(Box, {
      to: `/${nextPath}`,
      as: GatsbyLink
    }, React.createElement(Box, null, nextImage && React.createElement(GatsbyImage, {
      alt: "",
      image: nextImage
    })), React.createElement(Box, {
      p: {
        base: 5,
        md: 10
      }
    }, React.createElement(Heading, {
      as: "h4",
      size: "sm"
    }, " Next ", React.createElement(ArrowForwardIcon, {
      boxSize: 10
    })), nextTitle && React.createElement(Heading, {
      as: "h4",
      size: "lg",
      variant: "tri"
    }, nextTitle), subHeader && React.createElement(Text, {
      noOfLines: 4,
      fontSize: "md"
    }, subHeader)), React.createElement(Box, {
      color: colorMode === "dark" ? "brand.300" : "yellow.600"
    }, categories.map((c, p) => {
      if (c !== "project") {
        return p > categories.length - 1 ? `${c}` : React.createElement("span", {
          key: generate()
        }, " #", c, " • ");
      }
    })))) : React.createElement(React.Fragment);
  };
  const PrevArticle = () => {
    const {frontmatter: {featuredImage = "", subHeader = "", title: prevTitle = "", categories = []} = {}, fields: {path: prevPath} = {}} = previous || ({});
    const prevImage = featuredImage !== "" ? getImage(featuredImage) : defaultImg;
    console.log("previous");
    console.log(previous);
    return !(previous === ({})) ? React.createElement(CardOne, {
      key: generate()
    }, React.createElement(Box, {
      as: GatsbyLink,
      to: `/${prevPath}`
    }, React.createElement(Box, null, React.createElement(GatsbyImage, {
      alt: "",
      image: prevImage
    })), React.createElement(Box, {
      p: {
        base: 5,
        md: 10
      }
    }, React.createElement(Heading, {
      as: "h4",
      size: "sm"
    }, React.createElement(ArrowBackIcon, {
      boxSize: 10
    }), " Prev"), React.createElement(Heading, {
      as: "h4",
      size: "lg",
      variant: "tri"
    }, prevTitle), React.createElement(Text, {
      noOfLines: 4,
      fontSize: "md"
    }, subHeader)), React.createElement(Box, {
      color: colorMode === "dark" ? "brand.300" : "yellow.600"
    }, categories.map((c, p) => {
      if (c !== "project") {
        return p > categories.length - 1 ? `${c}` : React.createElement("span", {
          key: generate()
        }, " #", c, " • ");
      }
    })))) : React.createElement(React.Fragment);
  };
  const BreadCrumbs = () => React.createElement(Box, {
    mt: 6,
    mb: 10
  }, React.createElement(Breadcrumb, null, React.createElement(BreadcrumbItem, null, React.createElement(BreadcrumbLink, {
    size: "sm",
    to: "/",
    as: GatsbyLink
  }, " Home")), React.createElement(BreadcrumbItem, null, React.createElement(BreadcrumbLink, {
    to: "/projects",
    as: GatsbyLink
  }, " Projects")), React.createElement(BreadcrumbItem, {
    isCurrentPage: true
  }, React.createElement(BreadcrumbLink, null, title))));
  return React.createElement(React.Fragment, null, React.createElement(Box, {
    minHeight: "100vh",
    pt: 10,
    pb: 10
  }, React.createElement(Container, {
    maxW: "container.xl",
    py: 10
  }, React.createElement(Box, {
    as: Flex,
    justifyContent: "center"
  }, React.createElement(Box, null, React.createElement(Heading, {
    mt: 6,
    mb: 4,
    as: "h1",
    size: "2xl"
  }, title), React.createElement(BreadCrumbs), React.createElement(Flex, {
    flexWrap: "wrap",
    py: 6
  }, categories && categories.map((cat, i) => React.createElement(Tag, {
    variant: "sec",
    mb: 4,
    mr: 1,
    key: generate()
  }, cat))), React.createElement(Box, {
    display: "flex",
    flexDirection: {
      base: "column-reverse",
      lg: "row"
    },
    justifyContent: "space-between",
    as: "main"
  }, React.createElement(MDXLayout, null, React.createElement(Box, {
    width: {
      md: "650px"
    },
    py: 10,
    mr: {
      baser: 0,
      lg: 4
    }
  }, children)), React.createElement(MdxTOC, {
    tableOfContents: tableOfContents,
    pagePath: path,
    width: "20%"
  }))))), React.createElement(Container, {
    maxW: "container.xl",
    my: 10
  }, React.createElement(Box, {
    my: 10
  }, React.createElement(Heading, {
    as: "h3",
    size: "xl"
  }, " More Projects"), React.createElement(LinkOne, {
    as: GatsbyLink,
    to: "/projects"
  }, " All Projects")), React.createElement(HalfByHalfSection, {
    left: previous !== null ? React.createElement(PrevArticle) : React.createElement(React.Fragment),
    right: next !== null ? React.createElement(NextArticle) : React.createElement(React.Fragment)
  }))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(CaseTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query {
    defaultImage: file(relativePath: { eq: "illustration/6.png" }) {
      name
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
